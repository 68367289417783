import React, { useEffect, useRef, useState } from 'react';
import './testimonial.css';
import { Data } from './Data';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import Swiper_Navigation from '../../Components/Swiper/Swiper_Navigation';

import { obj_nutrition, obj_graphic, obj_program, obj_AI } from '../skill/skill_items';
import { Link } from 'react-router-dom';

const Testimonial = ({handle_click}) => {
  const [ slides_max_height, setSlides_max_height ] = useState(0);
  const [ items, setItems ] = useState([]);

  const ref_swiper = useRef(null);
  const ref = useRef([]);

  useEffect(() => {
    setTimeout(() => {
      if(ref.current) {
        const heights = ref.current.map(ref => ref?.scrollHeight || 0);
        setSlides_max_height(Math.max(...heights));
      }
    }, 1000);
  }, [ref]);

  useEffect(() => {
    let result = [];

    if(obj_nutrition) {
      const ref = [...obj_nutrition.example];
      ref.map(x => result.push(x));
    }

    if(obj_graphic) {
      const ref = [...obj_graphic.example];
      ref.map(x => result.push(x));
    }

    if(obj_program) {
      const ref = [...obj_program.example];
      ref.map(x => result.push(x));
    }

    if(obj_AI) {
      const ref = [...obj_AI.example];
      ref.map(x => result.push(x));
    }

    setItems(result);
  }, [obj_nutrition, obj_graphic, obj_program, obj_AI]);

  function getSwiper_Index(index, max_index) {
    if(index < 0) {
        return 0;
    }
    else if(index > max_index) {
        return max_index;
    }
    else {
        return index;
    }
  }

  return (
    <section className="w-auto h-fit mx-4" id='testimonial'>
        <p className="notosan-bold text-4xl text-black text-center">My past projects</p>
        <p className="notosan-reg text-black/50 text-sm text-center my-4">ผลงานที่ผ่านมา</p>

        <div className='relative w-auto h-fit my-14'>
          <div className='absolute top-1/2 left-0 w-fit h-fit z-10 hover:-translate-x-2/3 transition diration-300'>
            <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                const index = ref_swiper.current.swiper.activeIndex;
                ref_swiper?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 5));
            }}/>
          </div>

          <Swiper 
          ref={ref_swiper}
          className="testimonial__container"
          loop={true}
          grabCursor={true}
          spaceBetween={24}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 48,
            },
          }}
          modules={[Pagination, Autoplay]}
          >
            { 
              items.map((x, i) => {
                  return (
                      <SwiperSlide key={i}>
                        <div
                        className='bg-white border border-gray-300 rounded-lg'
                        
                        >
                          <div className='relative w-full h-28 overflow-hidden rounded-t-lg'>
                            <img className='w-full h-auto object-cover -translate-y-8' src={x.image}></img>
                            <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10'></div>
                          </div>

                          <div className='relative w-full h-12 border-t border-gray-300'>
                            <div className='absolute top-0 left-8 w-14 h-14 border border-gray-300 rounded-full overflow-hidden -translate-y-1/2 z-20'>
                              <img className='w-full h-auto' src={x.logo}/>
                            </div>
                          </div>

                          <div className='px-10 pb-10 flex flex-col' ref={(el) => (ref.current[i] = el)}
                          style={{ minHeight: slides_max_height }}>
                            <div className='w-full h-fit'>
                              <p className="notosan-med text-black">{x.title}</p>
                              <p className="notosan-reg text-black text-sm line-clamp-4 my-4">{x.description}</p>
                            </div>

                            <div className='w-full h-fit my-4 cursor-pointer grow flex justify-end items-end'>
                              <p className='w-fit h-fit notosan-med text-sm text-black/70' onClick={() => handle_click(items[i])}>ดูตัวอย่างผลงาน</p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                  )
              }) 
            }
          </Swiper>

          <div className='absolute top-1/2 right-0 w-fit h-fit z-10 hover:translate-x-2/3 transition diration-300'>
            <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                const index = ref_swiper.current.swiper.activeIndex;
                ref_swiper?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 5));
            }}/>
          </div>
        </div>
    </section>
  )
}

export default Testimonial