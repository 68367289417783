import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="w-full bg-white border-t-0.5 border-gray-300">
            <h1 className="text-center notosan-bold text-xl py-10">W@nder</h1>

            <span className="footer__copy notosan-reg pb-4">
                &#169; All rigths reserved
            </span>
        </div>
    </footer>
  )
}

export default Footer