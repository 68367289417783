import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimationControls, useReducedMotion } from "framer-motion";
import Modal_Skill_Detail from './Modal_Skill_Detail';

/*Download Icons*/
import UilComplete from '@iconscout/react-unicons/icons/uil-check-circle';
import UilError from '@iconscout/react-unicons/icons/uil-exclamation-triangle';
import { Link } from 'react-router-dom';

const Skill_Card = ({info, custom, handle_click}) => {
    const [ hover, setHover ] = useState(false);
    const image_controls = useAnimationControls();
    const text_controls = useAnimationControls();

    const animationVariants_image = {
        hidden: {
            y: '100%',
        },
        visible: {
            y: 0,
        }
    }

    const animationVariants_text = {
        hidden: {
            opacity: 0,
            x: "5%",
        },
        visible: (index) => ({
            opacity: 100,
            x: 0,
            transition: {delay: index * 0.25, duration: 0.5},
        })
    }

    useEffect(() => {
        image_controls.start(hover ? 'visible' : 'hidden');
        text_controls.start(hover ? 'visible' : 'hidden');
    }, [hover]);

    return (
        <div className='w-full h-fit' onClick={() => handle_click(info)}>
            <motion.div 
            initial={{opacity: 0, y: 10}}
            whileInView={{opacity: 1, y: 0, transition: {duration: 1, delay: custom * 0.2}}}
            viewport={{once: true}}
            className='relative w-full h-40 lg:h-96 lg:h-full lg:max-h-80 object-cover row-span-3 rounded-md overflow-hidden cursor-pointer border border-gray-300'
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className='absolute bottom-0 left-0 mx-6 my-6 z-20'>
                    <motion.div
                    variants={animationVariants_text}
                    initial="hidden"
                    animate={text_controls}
                    custom={1}>
                        <p className='notosan-med text-lg text-white line-clamp-1'>{info.title}</p>
                    </motion.div>
                    
                    <motion.div
                    className='mt-px'
                    variants={animationVariants_text}
                    initial="hidden"
                    animate={text_controls}
                    custom={2}>
                        <p className='notosan-light text-white text-xs line-clamp-2'>{info.description}</p>
                    </motion.div>
                </div>

                <motion.div className='absolute top-0 left-0 w-full h-full bg-black opacity-70 z-10'
                variants={animationVariants_image}
                initial="hidden"
                animate={image_controls}/>

                <img src={info.image} className='w-full h-full object-cover object-center rounded-sm'/>
            </motion.div>

            <p className="block xl:hidden notosan-bold text-black text-center line-clamp-2 mt-4">{info.title}</p>
        </div>
    )
}

const Modal_Skill = ({isShow, details, handle_close}) => {
    const [ isVisible, setIsVisible ] = useState(true);
    const [ items, setItems ] = useState([]);

    const [ modal_skill_detail, setModal_skill_detail ] = useState(false);
    const [ obj_skill_detail, setObj_skill_detail ] = useState({});

    const ref_btn_close = useRef(null);

    useEffect(() => {
        setIsVisible(isShow);

        if(isShow) {
            setTimeout(() => {
                if(ref_btn_close.current) {
                    ref_btn_close.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 500);
        }
    }, [isShow]);

    useEffect(() => {
        if(Array.isArray(details.example)) {
            setItems([...details.example]);
        }
        else {
            setItems([]);
        }
    }, [details]);
    
    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-screen h-screen overflow-y-auto z-20 ' + (isShow ? 'flex justify-center items-start' : 'hidden')}>
                <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}
                transition={{ duration: 0.5 }}
                className='w-11/12 xl:w-4/6 h-fit bg-white px-8 sm:px-12 py-20 my-10 drop-shadow-md rounded-md'>
                    <div ref={ref_btn_close} className='w-full h-fit flex justify-end'>
                        <div className='w-fit h-fit cursor-pointer' onClick={() => handle_close()}>
                            <box-icon name='x'></box-icon>
                        </div>
                    </div>

                    <p className="notosan-bold text-xl text-black text-center">{details.title}</p>
                    <p className="notosan-reg text-black indent-12 my-4">{details.description}</p>

                    <div className='w-full h-fit grid grid-cols-1 md:grid-cols-2 my-0 md:my-6 mx-0 sm:mx-10'>
                        {
                            details.list.map((x, i) => {
                                return (
                                    <div key={i} className={'w-full h-fit flex gap-3 my-0 sm:my-px ' + (x.title === '' ? 'invisible' : 'visible')}>
                                        <div className='w-fit h-fit scale-75'>
                                            <box-icon name='check-circle'></box-icon>
                                        </div>

                                        <div className='grow'>
                                            <p className="notosan-med text-black">{x.title}</p>
                                            <p className="notosan-reg text-black text-sm text-black/70">{x.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <p className="notosan-semi text-2xl text-black mt-0 md:mt-10">ประโยชน์ที่คาดหวัง</p>
                    <div className='w-full h-fit my-4'>
                        {
                            details.benefit.map((x, i) => {
                                return (
                                    <div key={i} className={'w-full h-fit flex gap-3 my-px'}>
                                        <div className='w-fit h-fit scale-75'>
                                            <box-icon name='check-circle'></box-icon>
                                        </div>

                                        <p className="notosan-reg text-black">{x}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <p className="notosan-semi text-2xl text-black mt-10">ตัวอย่างผลงาน</p>
                    <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-10'>
                        {
                            items.map((x, i) => {
                                return (
                                    <Skill_Card key={i} info={x} custom={0}
                                    handle_click={(obj) => {
                                        setObj_skill_detail({...obj});
                                        setModal_skill_detail(true);
                                    }}/>
                                )
                            })
                        }
                    </div>

                    <div className='w-full h-fit flex justify-center'>
                        <button className='w-fit h-fit px-10 py-3 rounded-full bg-black text-white notosan-reg' onClick={() => handle_close()}>ปิดหน้านี้</button>
                    </div>
                </motion.div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-10 ' + (isShow ? 'block' : 'hidden')}></div>

            <Modal_Skill_Detail isShow={modal_skill_detail} detail={obj_skill_detail}
            handle_close={() => setModal_skill_detail(false)}/>
        </>
    )
}

export default Modal_Skill