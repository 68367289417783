import React, { useEffect, useRef, useState } from 'react';
import "./skill.css";
import Skill_Research from './Skill_Research';
import Skill_Graphic from './Skill_Graphic';
import Skill_Programing from './Skill_Programing';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Swiper_Navigation from '../../Components/Swiper/Swiper_Navigation';
import UnderlineEffectText from '../../Components/UnderlineEffectText/UnderlineEffectText';
import Modal_Skill from './Modal_Skill';

import { obj_nutrition, obj_graphic, obj_program, obj_AI } from './skill_items';

const Skill_Card = ({minHeight, title, list, index, handle_click}) => {
  return (
    <div className='w-full h-full flex flex-col justify-between py-10' style={{minHeight: minHeight + 'px'}}>
      <div className='w-full h-fit'>
        <p className="notosan-reg text-xs text-black/70 text-center">{index}</p>
        <p className="notosan-bold text-black text-center mt-4">{title}</p>
      </div>

      <div className='w-full h-fit my-10 grow'>
        {
          list.map((x, i) => {
            return (
              <div key={i} className={'w-full h-fit flex gap-3 my-3 ' + (x.title === '' ? 'invisible' : 'visible')}>
                <div className='w-fit h-fit scale-75'>
                  <box-icon name='check-circle'></box-icon>
                </div>

                <div className='grow'>
                  <p className="notosan-med text-black">{x.title}</p>
                  <p className="notosan-reg text-black text-sm text-black/70">{x.description}</p>
                </div>
              </div>
            )
          })
        }
      </div>

      <div className='w-full h-fit flex justify-center'>
        <button className='w-fit h-fit px-10 py-3 rounded-full bg-black text-white notosan-reg' onClick={() => handle_click()}>รายละเอียดเพิ่มเติม</button>
      </div>
    </div>
  )
}

const Skill = ({handle_click}) => {
  const [ slides_max_height, setSlides_max_height ] = useState(0);

  const ref_swiper = useRef(null);
  const ref_slide1 = useRef(null);
  const ref_slide2 = useRef(null);
  const ref_slide3 = useRef(null);
  const ref_slide4 = useRef(null);

  useEffect(() => {
    let result = [];

    if(ref_slide1.current) {
      result.push(ref_slide1.current.scrollHeight);
    }

    if(ref_slide2.current) {
      result.push(ref_slide2.current.scrollHeight);
    }
    
    if(ref_slide3.current) {
      result.push(ref_slide3.current.scrollHeight);
    }

    if(ref_slide4.current) {
      result.push(ref_slide4.current.scrollHeight);
    }

    if(result.length > 0) {
      setSlides_max_height(Math.max(...result));
    }
  }, [ref_slide1, ref_slide2, ref_slide3, ref_slide4]);

  function getSwiper_Index(index, max_index) {
    if(index < 0) {
        return 0;
    }
    else if(index > max_index) {
        return max_index;
    }
    else {
        return index;
    }
  }

  return (
    <section className="skills section" id="skills">
      <p className="notosan-bold text-4xl text-black text-center">Skills</p>
      <p className="notosan-reg text-black/50 text-sm text-center my-4">ทักษะที่หลากหลายเพื่อองค์กรของคุณ</p>
      <p className="w-5/6 sm:w-1/2 notosan-reg text-black text-center my-4 mx-auto">ทักษะต่างๆ ทั้ง 4 ด้าน <span className='notosan-med underline'>ทั้งในด้านโภชนาการ การออกแบบกราฟิก การเขียนโค้ด และปัญญาประดิษฐ์ (AI)</span> ทักษะเหล่านี้สามารถนำมาใช้ส่วนเสริมการทำงานขององค์กรให้มีประสิทธิภาพมากยิ่งขึ้น สามารถพัฒนาโปรแกรมประเมินหรือโปรแกรมจัดการเอกสารที่จำเพาะต่อองค์กร ทักษะทั้ง 4 ด้านทำให้สามารถพัฒนาสื่อได้ทุกรูปแบบเพื่อให้ความรู้และประชาสัมพันธ์ได้เป็นอย่างดี นอกจากนี้ การมีความรู้เรื่องการเขียนโปรแกรมยังช่วยให้มีความรู้ความเข้าใจเกี่ยวกับฐานข้อมูลมากยิ่งขึ้น ซึ่งจะมีส่วนช่วยในการวิจัย โดยเฉพะางานวิจัยในสายโภชนชุมชนที่ต้องการการประชาสัมพันธ์และการจัดการกับข้อมูลจำนวนมหาศาล</p>

      <div className='relative w-auto h-fit my-14 mx-4 sm:mx-20'>
        <div className='absolute top-1/2 -translate-y-1/2 left-0 w-fit h-fit z-10 hover:-translate-x-2/3 transition diration-300'>
          <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
              const index = ref_swiper.current.swiper.activeIndex;
              ref_swiper?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 5));
          }}/>
        </div>

        <Swiper
        ref={ref_swiper}
        className='w-full h-fit my-14'
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1237: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            850: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        }}
        modules={[Pagination]}
        >
          <SwiperSlide 
          ref={ref_slide1}
          className='w-full h-full border border-gray-300 rounded-md px-6 sm:px-10 cursor-grab'
          style={{minHeight: slides_max_height + 'px'}}>
            <Skill_Card 
            minHeight={slides_max_height}
            title={obj_nutrition.title}
            list={obj_nutrition.list}
            index={'1/4'}
            handle_click={() => handle_click(0)}/>
          </SwiperSlide>

          <SwiperSlide 
          ref={ref_slide2}
          className='w-full h-full border border-gray-300 rounded-md px-6 sm:px-10 cursor-grab'
          style={{minHeight: slides_max_height + 'px'}}>
            <Skill_Card 
            minHeight={slides_max_height}
            title={obj_graphic.title}
            list={obj_graphic.list}
            index={'2/4'}
            handle_click={() => handle_click(1)}/>
          </SwiperSlide>

          <SwiperSlide 
          ref={ref_slide3}
          className='w-full h-full border border-gray-300 rounded-md px-6 sm:px-10 cursor-grab'
          style={{minHeight: slides_max_height + 'px'}}>
            <Skill_Card 
            minHeight={slides_max_height}
            title={obj_program.title}
            list={obj_program.list}
            index={'3/4'}
            handle_click={() => handle_click(2)}/>
          </SwiperSlide>

          <SwiperSlide 
          ref={ref_slide4}
          className='w-full h-full border border-gray-300 rounded-md px-6 sm:px-10 cursor-grab'
          style={{minHeight: slides_max_height + 'px'}}>
            <Skill_Card 
            minHeight={slides_max_height}
            title={obj_AI.title}
            list={obj_AI.list}
            index={'4/4'}
            handle_click={() => handle_click(3)}/>
          </SwiperSlide>
        </Swiper>

        <div className='absolute top-1/2 -translate-y-1/2 right-0 w-fit h-fit z-10 hover:translate-x-2/3 transition diration-300'>
          <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
              const index = ref_swiper.current.swiper.activeIndex;
              ref_swiper?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 5));
          }}/>
        </div>
      </div>
    </section>
  )
}

export default Skill