import React, { useState } from 'react';

const UnderlineEffectText = ({text, active, handle_click}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-fit h-fit cursor-pointer'
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        onClick={() => handle_click()}>
            <p className="w-fit h-fit notosan-bold text-black">{text}</p>
            <div className={'h-1 bg-black rounded-full mt-1.5 transition-all duration-500 origin-bottom-left ease-out ' + ((hover || active) ? 'w-full' : 'w-0')}></div>
        </div>
    )
}

export default UnderlineEffectText