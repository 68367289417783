import React, { useState } from 'react';
import './qualification.css';

import { UilGraduationCap } from '@iconscout/react-unicons';
import { UilBriefcase } from '@iconscout/react-unicons';
import UnderlineEffectText from '../../Components/UnderlineEffectText/UnderlineEffectText';

const Button_Education = ({active, handle_click}) => {
    return (
        <div className="qualification__button qualification__active flex items-start gap-2">
            <UilGraduationCap/>

            <UnderlineEffectText 
            text={'Education'}
            active={active}
            handle_click={() => handle_click()}/>
        </div>
    )
}

const Button_Experience = ({active, handle_click}) => {
    return (
        <div className="qualification__button qualification__active flex items-start gap-2">
            <UilBriefcase/>
            <UnderlineEffectText 
            text={'Experience'}
            active={active}
            handle_click={() => handle_click()}/>
        </div>
    )
}

const Item = ({index, title, subtitle, year}) => {
    const Center_Line = () => {
        return (
            <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
            </div>
        )
    }

    return (
        <>
            {
                (index%2 === 0) ? 
                    <div className="qualification__data">
                        <div>
                            <p className="notosan-semi text-lg text-black text-end">{title}</p>
                            <p className="notosan-reg text-sm text-black/70 text-end">{subtitle}</p>

                            <div className="notosan-reg text-sm text-black/70 mt-2 flex justify-end items-center gap-1.5">
                                <div className='w-fit h-fit scale-90'>
                                    <box-icon name='calendar' color='#8e8e8e' ></box-icon>
                                </div>
                                {year}
                            </div>
                        </div>

                        <Center_Line/>
                    </div>
                :
                    <div className="qualification__data">
                        <div></div>

                        <Center_Line/>

                        <div>
                            <p className="notosan-semi text-lg text-black">{title}</p>
                            <p className="notosan-reg text-sm text-black/70">
                                {subtitle}
                            </p>

                            <div className="notosan-reg text-sm text-black/70 mt-2 flex items-center gap-1.5">
                                <div className='w-fit h-fit scale-90'>
                                    <box-icon name='calendar' color='#8e8e8e' ></box-icon>
                                </div>
                                {year}
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    }

    const Education = [
        {
            title: 'ประถมศึกษา',
            subtitle: 'โรงเรียนอนุบาลวัดอุทยานนที ชลบุรี',
            year: '2000 - 2006',
        },
        {
            title: 'มัธยมศึกษา',
            subtitle: 'โรงเรียนชลราษฎรอํารุง ชลบุรี',
            year: '2006 - 2012',
        },
        {
            title: 'ปริญญาตรี',
            subtitle: 'คณะสหเวชศาสตร์มหาวิทยาลัยบูรพา ชลบุรี',
            year: '2012 - 2016',
        },
        {
            title: 'ปริญญาโท',
            subtitle: 'คณะสหเวชศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย กรุงเทพฯ',
            year: '2017 - 2023',
        },
    ]

    const Experience = [
        {
            title: 'ผู้ช่วยวิจัย',
            subtitle: 'คณะสหเวชศาสตร์ มหาวิทยาลัยบูรพา ชลบุรี',
            year: '2015 - 2016',
        },
        {
            title: 'ผู้ช่วยวิจัย',
            subtitle: 'ศูนย์โรคไต โรงพยาบาลศิริราช กทมฯ',
            year: '2017 - 2018',
        },
        {
            title: 'นักกำหนดอาหาร',
            subtitle: '- โรงพยาบาลภูมิพลฯ กทมฯ - โรงพยาบาลศูนย์การแพทย์ฯ นครนายก',
            year: '2018 - 2022',
        },
        {
            title: 'ผู้ช่วยวิจัย (สัญญาระยะสั้น)',
            subtitle: 'โครงการสมองเสื่อมอัลไซเมอร์ครบวงจรในคนไทย (AD) ประจำราชวิทยาลัยจุฬาภรณ์ กทมฯ',
            year: '2022 - 2023',
        },
        {
            title: 'นักวิชาการโสตทัศนศึกษา',
            subtitle: 'ศูนย์นโยบายและการจัดการสุชภาพ คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี มหาวิทยาลัยมหิดล',
            year: '2023 - ปัจจุบัน',
        },
    ]

    return (
        <section className="qualification section" id='experience'>
            <p className="notosan-bold text-4xl text-black text-center">Experience</p>
            <p className="notosan-reg text-black/50 text-sm text-center my-4">ประสบการณ์ที่ผ่านมา</p>

            <div className="w-full xl:w-1/2 mx-auto my-14">
                <div className="qualification__tabs">
                    <Button_Education active={toggleState === 1} handle_click={() => toggleTab(1)}/>
                    <Button_Experience active={toggleState === 2} handle_click={() => toggleTab(2)}/>
                </div>

                <div className="qualification__sections">
                    <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        {
                            Education.map((x, i) => {
                                return (
                                    <Item 
                                    key={i} index={i} 
                                    title={x.title}
                                    subtitle={x.subtitle}
                                    year={x.year}/>
                                )
                            })
                        }

                        <div className="qualification__next">
                            <div className="qualification__next-box">
                                <p className="notosan-semi text-lg text-black text-center">What's Next?</p>
                                <p className="notosan-reg text-sm text-black/70 text-center">กำลังสนใจ...</p>

                                <p className="notosan-med text-black text-center mt-4">3D modeling (Blender)</p>
                                <p className="notosan-reg text-xs text-black/70 text-center">ศึกษาต่อยอดการปั้นโมเดล 3 มิติให้มากยิ่งขึ้น</p>

                                <p className="notosan-med text-black text-center mt-4">Stable Diffusion AI</p>
                                <p className="notosan-reg text-xs text-black/70 text-center">ศึกษาต่อยอดการการใช้ AI เจนภาพให้มากยิ่งขึ้น</p>
                            </div>
                        </div>
                    </div>

                    <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        {
                            Experience.map((x, i) => {
                                return (
                                    <Item 
                                    key={i} index={i} 
                                    title={x.title}
                                    subtitle={x.subtitle}
                                    year={x.year}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Qualification