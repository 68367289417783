import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimationControls, useReducedMotion } from "framer-motion";
import { Link } from 'react-router-dom';

const Button_close = ({handle_close}) => {
    const [ hover, setHover ] = useState(false);

    return (
        <div className={'w-60 h-fit px-4 sm:px-10 py-3 rounded-full notosan-reg text-center text-sm sm:text-base cursor-pointer border border-black transition-colors duration-300 ' + (hover ? 'bg-black text-white ' : 'bg-white text-black')} 
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => handle_close()}>ปิดหน้านี้</div>
    )
}

const Modal_Skill_Detail = ({isShow, detail, handle_close}) => {
    const [ isVisible, setIsVisible ] = useState(true);
    const ref_btn_close = useRef(null);

    useEffect(() => {
        setIsVisible(isShow);

        if(isShow) {
            setTimeout(() => {
                if(ref_btn_close.current) {
                    ref_btn_close.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 500);
        }
    }, [isShow]);

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-screen h-screen overflow-y-auto z-40 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}
                transition={{ duration: 0.5 }}
                className='w-11/12 xl:w-4/6 h-fit bg-white px-8 sm:px-12 py-16 sm:py-20 my-10 drop-shadow-md rounded-md'>
                    <div ref={ref_btn_close} className='w-full h-fit flex justify-end'>
                        <div className='w-fit h-fit cursor-pointer' onClick={() => handle_close()}>
                            <box-icon name='x'></box-icon>
                        </div>
                    </div>

                    <img className='w-full h-60 sm:h-auto max-h-96 border border-gray-300 rounded-md object-cover mx-auto' src={detail.image}></img>

                    <p className="notosan-bold text-xl text-black mt-6 sm:mt-10 mb-4">{detail.title}</p>
                    <p className="notosan-reg text-black indent-12 my-4">{detail.description}</p>

                    <div className='w-full h-fit flex justify-center gap-3 mt-10'>
                        <Button_close handle_close={() => handle_close()}/>

                        <Link to={detail.url} target="_blank" rel="noopener noreferrer">
                            <div className='w-60 h-fit px-4 sm:px-10 py-3 rounded-full bg-black text-white text-sm sm:text-base notosan-reg text-center cursor-pointer' onClick={() => handle_close()}>ดูตัวอย่าง</div>
                        </Link>
                    </div>
                </motion.div>
            </div>

            { /* Modal detail backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-30 ' + (isShow ? 'block' : 'hidden')}></div>
        </>
    )
}

export default Modal_Skill_Detail