import React from 'react';
import { UilAppleAlt } from '@iconscout/react-unicons';
import { UilYoutube } from '@iconscout/react-unicons';
import { UilArrow } from '@iconscout/react-unicons';
import { UilAdobe } from '@iconscout/react-unicons';
import { UilWindows } from '@iconscout/react-unicons';

const Social = () => {
  return (
    <div className="grid">
        <div className="home__social-icon">
          <UilAppleAlt/>
        </div>

        <div className="home__social-icon">
          <UilYoutube/>
        </div>

        <div className="home__social-icon">
          <UilArrow/>
        </div>

        <div className="home__social-icon">
          <UilAdobe/>
        </div>

        <div className="home__social-icon">
          <UilWindows/>
        </div>
    </div>
  )
}

export default Social