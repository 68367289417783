import React, { useState } from 'react';
import Header from './Header/Header';
import Home from './Home/Home';
import About from './about/About';
import Skill from './skill/Skill';
import Service from './services/Services';
import Qualification from './qualification/Qualification';
import Testimonial from './testimonial/Testimonial';
import Contact from './contact/Contact';
import Footer from './footer/Footer';
import ScrollUp from './scrollup/ScrollUp';
import './portfolio.css';
import Modal_Skill from './skill/Modal_Skill';

import { obj_nutrition, obj_graphic, obj_program, obj_AI } from './skill/skill_items';
import Modal_Skill_Detail from './skill/Modal_Skill_Detail';

const Portfolio = () => {
    const [ modal_skill_nutrition, setModal_skill_nutrition ] = useState(false);
    const [ modal_skill_graphic, setModal_skill_graphic ] = useState(false);
    const [ modal_skill_programe, setModal_skill_programe ] = useState(false);
    const [ modal_skill_ai, setModal_skill_ai ] = useState(false);

    const [ modal_testimonial_skill_detail, setModal_testimonial_skill_detail ] = useState(false);
    const [ modal_testimonial_obj_detail, setModal_testimonial_obj_detail ] = useState({});

    return (
        <>
            <div className='relative w-screen h-screen overflow-y-scroll'>
                { /* Content */ }
                <Header/>

                <main className='main'>
                    <Home/>
                    <About/>
                    <Skill handle_click={(index) => {
                        setModal_skill_nutrition(index === 0);
                        setModal_skill_graphic(index === 1);
                        setModal_skill_programe(index === 2);
                        setModal_skill_ai(index === 3);
                    }}/>

                    <Qualification/>
                    <Testimonial handle_click={(obj) => {
                        setModal_testimonial_skill_detail(true);
                        setModal_testimonial_obj_detail({...obj});
                    }}/>

                    <Contact/>
                </main>

                <Footer/>
            </div>
            
            <Modal_Skill isShow={modal_skill_nutrition} details={obj_nutrition} handle_close={() => setModal_skill_nutrition(false)}/>
            <Modal_Skill isShow={modal_skill_graphic} details={obj_graphic} handle_close={() => setModal_skill_graphic(false)}/>
            <Modal_Skill isShow={modal_skill_programe} details={obj_program} handle_close={() => setModal_skill_programe(false)}/>
            <Modal_Skill isShow={modal_skill_ai} details={obj_AI} handle_close={() => setModal_skill_ai(false)}/>
            <Modal_Skill_Detail isShow={modal_testimonial_skill_detail} detail={modal_testimonial_obj_detail} handle_close={() => setModal_testimonial_skill_detail(false)}/>
        </>
    )
}

export default Portfolio