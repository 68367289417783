import React from 'react';

const Box = ({title, subtitle}) => {
  return (
    <div className="w-full md:w-32 h-fit bg-white border border-gay-300 rounded-md py-4 px-6 xl:px-2">
        <p className="notosan-med text-black text-sm text-center">{title}</p>
        <p className="notosan-reg text-black text-xs text-center text-black/70 mt-2">{subtitle}</p>
    </div>
  )
}

const Info = () => {
  return (
    <div className="w-full md:w-fit grid xl:flex grid-cols-1 md:grid-cols-3">
        <Box title={'Experience'} subtitle={'> 6 ปี'}/>
        <Box title={'TOEIC'} subtitle={'650 คะแนน'}/>
        <Box title={'GPAX'} subtitle={'3.48'}/>
    </div>
  )
}

export default Info