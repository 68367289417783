import logo_chulaporn from "../../../assets/portfolio/testimonial/logo/testimonial_chulaporn_logo.png";
import logo_chula from "../../../assets/portfolio/testimonial/logo/testimonial_chula_logo.png";
import logo_TDA from "../../../assets/portfolio/testimonial/logo/testimonial_TDA_logo.png";
import logo_brkidney from "../../../assets/portfolio/testimonial/logo/brkidney.jpg";
import logo_swu from "../../../assets/portfolio/testimonial/logo/swu.jpg";
import logo_phyathai from "../../../assets/portfolio/testimonial/logo/phyathai.jpg";
import logo_mahidol from "../../../assets/portfolio/testimonial/logo/mahidol.jpg";
import logo_kidney_vector from "../../../assets/portfolio/testimonial/logo/kidney_vector.jpg";

import chulaporn_nt2013 from "../../../assets/portfolio/testimonial/cover/chulahorn_nt2013.jpg";
import chula_ndpro from "../../../assets/portfolio/testimonial/cover/ndpro.jpg";
import chula_spent_ndpro from "../../../assets/portfolio/testimonial/cover/spent_ndpro.jpg";
import tda_potassium from "../../../assets/portfolio/testimonial/cover/poster_potassium.jpg";
import brkidney_read_label from "../../../assets/portfolio/testimonial/cover/brkidney_read_label.jpg";
import swu_kidney from "../../../assets/portfolio/testimonial/cover/swu_kidney.jpg";
import LINE_chatbot from "../../../assets/portfolio/testimonial/cover/line.jpg";
import fscu from "../../../assets/portfolio/testimonial/cover/fscu.jpg";
import chpm from "../../../assets/portfolio/testimonial/cover/chpm.jpg";
import tinder from "../../../assets/portfolio/testimonial/cover/tinder.jpg";
import covid_nutrition from "../../../assets/portfolio/testimonial/cover/covid_nutrition.jpg";

export const obj_nutrition = {
    title: 'กำหนดอาหารและวิจัย',
    description: 'ทักษะด้านโภชนาการและการวิจัยเป็นสิ่งสำคัญในการทำงานเป็นนักกำหนดอาหารในโรงพยาบาล ไม่ว่าจะเป็นการประเมินการบริโภคอาหารของผู้ป่วยผ่านโปรแกรม INMUCAL การวิเคราะห์ข้อมูลทางสถิติเพื่อสนับสนุนงานวิจัยด้วย IBM SPSS หรือการใช้เครื่องมือดิจิทัล เช่น Microsoft Office และ Looker Studio เพื่อจัดการข้อมูลและนำเสนอผลลัพธ์อย่างมีประสิทธิภาพ ความสามารถเหล่านี้ช่วยให้สามารถออกแบบแนวทางการรักษาทางโภชนาการที่เหมาะสม รวมถึงสนับสนุนการพัฒนางานวิจัยที่มีคุณภาพของหน่วยงาน',
    list: [
        {
            title: 'INMUCAL',
            description: 'สามารถใช้งานเพื่อประเมินการบริโภคอาหาร'
        },
        {
            title: 'IBM SPSS',
            description: 'สามารถใช้งานเพื่อการวิเคราะห์ทางสถิติ'
        },
        {
            title: 'Microsoft Office',
            description: 'สามารถใช้งานโปรแกรม Microsoft Office ทั้ง Word, PowerPoint, และ Excel'
        },
        {
            title: 'Looker Studio',
            description: 'สามารถใช้งานโปรแกรมเพื่อการสร้าง Data Visualization'
        },
        {
            title: 'Canva',
            description: 'สามารถใช้งานโปรแกรมเพื่อนำเสนอผลงาน'
        },
        {
            title: '',
            description: ''
        },
    ],
    benefit: [
        'สามารถทำงานออฟฟิศขั้นพื้นฐานได้เป็นอย่างดี นอกจากนี้ยังมีทักษะภาษาอังกฤษที่เพียงพอสำหรับการทำงานอย่างมีประสิทธิภาพ',
        'สามารถประเมินการบริโภคพลังงานและสารอาหารจากข้อมูลที่เก็บรวบรวมได้',
        'สามารถจัดเตรียมสื่อการสอนหรือสื่อเพื่ออบรมทางวิชาการ',
        'สามารถประมวลและวิเคราะห์ผลทางสถิติได้',
        'สามารถสร้าง Data Visualization เพื่อการวิเคราะห์ข้อมูลที่เก็บรวบรวมได้',
    ],
    example: [
        {
            title: 'เล่มวิทยานิพนธ์ระดับปริญญาโท',
            image: chula_ndpro,
            description: 'รายละเอียดและเนื้อหาวิทยานิพนธ์ที่ทำในช่วงเรียนปริญญาโท ในหัวข้อ Development and validation of smartphone nutrition application for supporting dietetic professional practice ซึ่งเป็นจุดเริ่มต้นที่ทำให้เริ่มฝึกเขียนโปรแกรมในการพัฒนาเครื่องมือต่างๆ ด้านโภชนาการ',
            logo: logo_chula,
            url: 'https://drive.google.com/drive/folders/1UUnMWBnBF8HS6tzHuqTglemogHg-Bzhw?usp=sharing',
        },
        {
            title: 'โปสเตอร์นำเสนอวิทยานิพนธ์ในงาน SPENT',
            image: chula_spent_ndpro,
            description: 'โปสเตอร์ที่ใช้นำเสนอวิทยานิพนธ์ ในหัวข้อ Development and validation of smartphone nutrition application for supporting dietetic professional practice ภายในงาน SPENT ปี 2023 ที่ผ่านมา',
            logo: logo_chula,
            url: 'https://drive.google.com/drive/folders/1fU1AV04EHVVQgO8hextqzOt9kBo5x6I9?usp=sharing',
        },
    ],
};

export const obj_graphic = {
    title: 'กราฟฟิกดีไซน์',
    description: 'ทักษะด้านกราฟิกดีไซน์และดิจิตอลอาร์ตเป็นเครื่องมือสำคัญในการสื่อสารข้อมูลทางโภชนาการและสุขภาพให้น่าสนใจและเข้าถึงง่าย ไม่ว่าจะเป็นการออกแบบสื่อให้ความรู้ผู้ป่วย การสร้างอินโฟกราฟิกที่เข้าใจง่าย หรือการผลิตสื่อวิดีโอเพื่อการศึกษา ความเชี่ยวชาญในซอฟต์แวร์ต่าง ๆ เช่น Adobe Photoshop, Illustrator, และ After Effects ช่วยให้สามารถสร้างสื่อการสอนในรูปแบบต่างๆ เพื่อนำเสนอข้อมูลทางวิชาการได้อย่างมีประสิทธิภาพ เพิ่มความน่าสนใจ และส่งเสริมการเรียนรู้ด้านโภชนาการของผู้ป่วยและชุมชน',
    list: [
        {
        title: 'Adobe Photoshop',
        description: 'สามารถใช้งานโปรแกรมเพื่อวาดและตัดต่อรูปดิจิตอล'
        },
        {
        title: 'Adobe Illustrator',
        description: 'สามารถใช้งานโปรแกรมเพื่อวาดและตัดต่อรูปดิจิตอล ประเภทเวคเตอร์'
        },
        {
        title: 'Adobe Lightroom',
        description: 'สามารถใช้งานโปรแกรมเพื่อการปรับแต่งรูปถ่าย'
        },
        {
        title: 'Adobe After Effect',
        description: 'สามารถใช้งานโปรแกรมเพื่อการสร้างสื่อวิดีโอ'
        },
        {
        title: 'Adobe Premiere Pro',
        description: 'สามารถใช้งานโปรแกรมเพื่อการสร้างและตัดต่อสื่อวิดีโอ'
        },
        {
        title: '',
        description: ''
        },
    ],
    benefit: [
        'ช่วยออกแบบและสร้างสื่อการสอนประเภทดิจิตอลกราฟฟิก เช่น โปสเตอร์ แผ่นพับ หนังสือ เป็นต้น สำหรับการให้ความรู้ด้านโภชนาการและสุขภาพ',
        'ผลิตสื่อวิดีโอเพื่อการศึกษาและการอบรมทางโภชนาการ',
        'ช่วยสร้างโมเดล 3 มิติสำหรับออกแบบและพัฒนาสื่อการสอนตามความเหมาะสม',
        'ช่วยทำให้สไลด์นำเสนอสำหรับการให้ความรู้ด้านโภชนาการและสุขภาพมีความสวยงามมากยิ่งขึ้น',
        'เมื่อรวมกับทักษะการเขียนโปรแกรม ทำให้สามารถพัฒนาสื่อประเภทอื่นๆ ได้เพิ่มเติม เช่น เว็บไซต์ แอพพลิเคชั่น แชทบอท เป็นต้น',
        'เมื่อรวมกับทักษะการใช้งาน AI สร้างรูปภาพ ทำให้สามารถผลิตสื่อที่มีความสวยงามโดยใช้เวลาน้อยลงยิ่งขึ้น',
        'มีทักษะภาษาอังกฤษที่ดี ทำให้สามารถออกแบบและพัฒนาสื่อดิจิตอลกราฟฟิกทั้งภาษาไทยและภาษาอังกฤษได้เป็นอย่างดี',
    ],
    example: [
        {
            title: 'โปสเตอร์ 4 วิธีลดโพแทสเซ๊ยมในอาหาร',
            image: tda_potassium,
            description: 'โปสเตอร์หัวข้อ 4 วิธีลดโพแทสเซ๊ยมในอาหาร เป็นโปสเตอร์ได้รับโอกาสจากการได้ร่วมงานกับสมาคมนักกำหนดอาหารและสมาคมโรคไตแห่งประเทศไทย เพื่อนำไปเสนอในงานวิขาการด้านโภชนาการของสมาคม',
            logo: logo_TDA,
            url: 'https://drive.google.com/drive/folders/1TanncQyGrh_91UncbJHmpKHfE9Gyup4_?usp=sharing',
        },
        {
            title: 'โปสเตอร์ข้อแนะนำการอ่านฉลากโภชนาการ',
            image: brkidney_read_label,
            description: 'โปสเตอร์หัวข้อ ข้อแนะนำการอ่านฉลากโภชนาการ เป็นโปสเตอร์ได้รับโอกาสจากการได้ร่วมงานกับสถาบันโรคไตภูมิราชนครินทร์ เพื่อนำไปเสนอในงานวิขาการด้านโภชนาการของสถาบัน โดยมีกลุ่มเป้าหมายในการให้ความรู้เป็นกลุ่มเด็กประถมศึกษา',
            logo: logo_brkidney,
            url: 'https://drive.google.com/drive/folders/1i0itq5_NFqVz_n6ZVsN0k6gKOSBqLFEX?usp=sharing',
        },
        {
            title: 'หนังสือปัญหาทางช่องปากและโภชนาการในผู้ป่วยโรคไตเรื้อรัง',
            image: swu_kidney,
            description: 'ผลงานหนังสือที่ได้รับโอกาสจากโรงพยาบาลสมเด็จพระเทพฯ, ศูนย์การแพทย์และฟื้นฟูบึงยี่โถ, และสถาบันโภชนาการ มหาวิทยาลัยมหิดล ในการจัดทำหนังสือความรู้แก่ผู้ป่วยโรคไตเรื้อรังระยะก่อนล้างไต',
            logo: logo_swu,
            url: 'https://drive.google.com/drive/folders/15IpPa6Xy7S-SylCpQCsWwC2p5wlyJxjy?usp=sharing',
        },
        {
            title: 'โปสเตอร์การสั่งและรับประทานอาหารออนไลน์ให้ได้สุขภาพในช่วง COVID',
            image: covid_nutrition,
            description: 'โปสเตอร์หัวข้อ ข้อแนะนำการอ่านฉลากโภชนาการ เป็นโปสเตอร์ได้รับโอกาสจากสมาคมนักกำหนดอาหารแห่งประเทศไทย เพื่อนำไปใช้เผยแพร่ความรู้ความเข้าใจที่ถูกต้องในช่วงการระบาดของเชื้อ COVID',
            logo: logo_TDA,
            url: 'https://drive.google.com/drive/folders/1YoEcu_zPZHRjHqWxeHglsMWiaqaUWyOU?usp=sharing',
        },
    ],
};

export const obj_program = {
    title: 'โปรแกรมมิ่ง',
    description: 'ปัจจุบัน เทคโนโลยีและสารสนเทศเข้ามามีส่วนช่วยในการทำงานในหลายองค์กร ทักษะด้านการเขียนโปรแกรมจึงเป็นอีกหนึ่งองค์ประกอบสำคัญที่ช่วยเพิ่มประสิทธิภาพในการทำงานด้านโภชนาการและการวิจัยทางการแพทย์ การพัฒนาเครื่องมือดิจิทัล เช่น การสร้างเว็บไซต์เพื่อใช้เป็นเว็บแอพหรือใช้เป็นช่องทางในการสื่อสารกับผู้ป่วยและชุมชน การใช้ Python และ SQL เพื่อจัดการและวิเคราะห์ฐานข้อมูลทางสุขภาพที่มีขนาดใหญ่ เป็นต้น',
    list: [
        {
        title: 'JavaScript',
        description: 'ภาษาโปรแกรมมิ่งสำหรับเขียนเว็บไซต์'
        },
        {
        title: 'Phython',
        description: 'ภาษาโปรแกรมมิ่งสำหรับเขียนโปรแกรม PC และ AI'
        },
        {
        title: 'JAVA',
        description: 'ภาษาโปรแกรมมิ่งสำหรับเขียนแอพพลิเคชั่น Android'
        },
        {
        title: 'SQL',
        description: 'ภาษาโปรแกรมมิ่งสำหรับสร้างและจัดการฐานข้อมูล'
        },
        {
        title: 'ReactJS',
        description: 'Framework สำหรับเขียนเว็บไซต์ขั้นสูง'
        },
        {
        title: '',
        description: ''
        },
    ],
    benefit: [
        'สามารถพัฒนาโปรแกรมเพื่อช่วยในการบันทึกและวิเคราะห์ข้อมูลโภชนาการที่มีความจำเพาะต่อความต้องการของหน่วยงานได้ เช่น โปรแกรมคำนวนพลังงานและสารอาหาร, โปรแกรมประเมิน SGA ออนไลน์, โปรแกรมประเมินการเจริญเติบโตของเด็ก เป็นต้น',
        'เนื่องจากมีองค์ความรู้เรื่องฐานข้อมูล ทำให้ช่วยออกแบบและพัฒนาฐานข้อมูลที่เหมาะสมสำหรับเก็บข้อมูลจำนวนมากในหน่วยงาน รวมถึงข้อมูลที่เก็บจากชุมชน',
        'สามารถพัฒนาช่องทางในการสื่อสารกับคนไข้ เช่น เว็บไซต์ หรือ แชทบอท เป็นต้น',
        'หากออกแบบและพัฒนาเครื่องมือที่เหมาะสมกับหน่วยงาน จะช่วยเพิ่มความแม่นยำและลดข้อผิดพลาดในการทำงานของหน่วยงาน ช่วยให้กระบวนการทำงานเป็นอัตโนมัติ ลดภาระงาน และเพิ่มประสิทธิภาพในการทำงาน',
        'สามารถพัฒนาโปรแกรม AI ได้',
        'เมื่อรวมกับทักษะการออกแบบและพัฒนาสื่อดิจิตอลกราฟฟิก จะช่วยทำให้การพัฒนาโปรแกรมมีความสวยงามมากยิ่งขึ้น',
    ],
    example: [
        {
            title: 'เว็บไซต์ของศูนย์นโยบายและการจัดการสุขภาพ คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี',
            image: chpm,
            description: 'พัฒนาเว็บไซต์และเว็บแอพพลิเคชั่นให้แก่ศูนย์นโยบายและการจัดการสุขภาพ คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี มหาวิทยาลัยมหิดล และยังช่วยดูแลระบบให้จนถึงปัจจุบัน',
            logo: logo_mahidol,
            url: 'https://wandertestsite.com/',
        },
        {
            title: 'เว็บไซต์ของโครงการระบบความปลอดภัยอาหารของโรงอาหารรวมในจุฬาลงกรณ์มหาวิทยาลัย',
            image: fscu,
            description: 'พัฒนาเว็บไซต์และเว็บแอพพลิเคชั่นให้แก่โครงการระบบความปลอดภัยอาหารของโรงอาหารรวมในจุฬาลงกรณ์มหาวิทยาลัย (คปอจ.) โดยคณะสหเวชศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย และยังช่วยดูแลระบบให้จนถึงปัจจุบัน',
            logo: logo_chula,
            url: 'http://www.fscu.ahs.chula.ac.th/',
        },
        {
            title: 'Dietary Record Online (จุฬาภรณ์)',
            image: chulaporn_nt2013,
            description: 'พัฒนาแพลตฟอร์มออนไลน์ให้กับโรงพยาบาลจุฬาภรณ์ สำหรับผู้ป่วยในการส่งภาพถ่ายมาเก็บในไฟล์ Excel สำหรับให้นักกำหนดอาหารนำไปประเมินอาหารในภายหลัง',
            logo: logo_chulaporn,
            url: 'https://script.google.com/macros/s/AKfycbyKun6yguvtc-x53WVBxErgdLN8-m9GxeV6pgomjdmCVKWtmczgcL1MZbWLgy_tXILx/exec',
        },
        {
            title: 'LINE Happy DM Chatbot',
            image: LINE_chatbot,
            description: 'พัฒนาไลน์แชทบอทให้กับโรงพยาบาลจุฬาภรณ์ สำหรับให้ความรู้เรื่องเบาหวานแก่คนไข้และชุมชน ภายใต้ชื่อบัญชี Happy DM Chat แพลตฟอร์มนี้สามารถนำมาใช้แทนแผ่นพับความรู้ที่ช่วยลดการใช้กระดาษภายในหน่วยงานได้',
            logo: logo_chulaporn,
            url: 'https://line.me/R/ti/p/@123dcwoj',
        },
        {
            title: 'LINE Kin-Dee ชิด Chat',
            image: LINE_chatbot,
            description: 'พัฒนาไลน์แชทบอทให้กับโรงพยาบาลพญาไท2 สำหรับให้ความรู้เรื่องโภชนาการเฉพาะโรคแก่คนไข้และชุมชน ภายใต้ชื่อบัญชี Kin-Dee ชิด Chat แพลตฟอร์มนี้สามารถนำมาใช้แทนแผ่นพับความรู้ที่ช่วยลดการใช้กระดาษภายในหน่วยงานได้',
            logo: logo_phyathai,
            url: 'https://line.me/R/ti/p/@025oxaqm',
        },
        {
            title: 'Potassium Tinder',
            image: tinder,
            description: 'โปรเจค Mini game เพื่อเป็นสื่อการสอนเกี่ยวกับโพแทสเซียมในผักและผลไม้ในการอบรมเรื่องโรคไตแก่พยาบาล โดยได้รับแรงบรรดาลใจมาจากแอพพลิเคชั่น Tinder',
            logo: logo_kidney_vector,
            url: 'https://wander-portfolio.com/potassium',
        },
    ],
};

export const obj_AI = {
    title: 'AI',
    description: 'ปัจจุบัน AI ได้เข้ามามีบทบาทในองค์กรต่างๆ มากขึ้น และทักษะทางด้าน AI เริ่มเข้ามาเป็นเครื่องมือสำคัญที่ช่วยเพิ่มประสิทธิภาพในการทำงานด้านโภชนาการ วิจัย และการสื่อสารทางการแพทย์ สามารถช่วยในการจัดทำเอกสาร วิเคราะห์ข้อมูล และพัฒนาเนื้อหาวิชาการ นอกจากนี้ การมีทักษะขณะที่ AI สำหรับสร้างรูปภาพ เช่น Stable Diffusion ทำให้สามารถใช้สร้างสื่อกราฟิกเพื่อการสื่อสารด้านสุขภาพได้รวดเร็วมากยิ่งขึ้นและลดกังวลกับปัญหาเรื่องลิขสิทธิ์และ PDPA',
    list: [
        {
            title: 'ChatGPT',
            description: 'AI ประเภท Text2Text เพื่อใช้จัดการงานเขียนบทความและเขียนโปรแกรม'
        },
        {
            title: 'Google Gemini',
            description: 'AI ประเภท Text2Text เพื่อใช้จัดการงานเขียนบทความและเขียนโปรแกรม'
        },
        {
            title: 'Claude.ai',
            description: 'AI ประเภท Text2Text เพื่อใช้จัดการงานเขียนบทความและเขียนโปรแกรม'
        },
        {
            title: 'Ollama',
            description: 'AI ประเภท Text2Text และ Image2Text แบบ Open source ทำให้สามารถนำมาใช้เขียนโปรแกรม AI ใช้เองภายในหน่วยงานได้'
        },
        {
            title: 'Stable Diffusion',
            description: 'AI ประเภท Text2Image และ Image2Image แบบ Open source ทำให้สามารถสร้างรูปภาพเองในเครื่องคอมพิวเตอร์ของหน่วยงาน ทำให้ไม่ต้องเสียค่าบริการเหมือนการใช้บริการ AI รูปภาพอื่นๆ'
        },
        {
            title: '',
            description: ''
        },
    ],
    benefit: [
        'ช่วยลดเวลาการจัดทำเอกสารและสรุปเนื้อหาทางวิชาการ',
        'ช่วยสร้างสื่อกราฟิกและภาพประกอบ ทำให้มีรูปประกอบสำหรับใช้งานโดยไม่ติดปัญหาเรื่องลิขสิทธิ์ หรือใช้สร้างโมเดลประกอบโดยไม่ต้องกังวลเรื่องสิทธิ์ PDPA อย่างไรก็ตาม การใช้งาน AI สร้างภาพมักมีปัญหาความผิดเพี้ยนในรายละเอียดบางจุดเกิดขึ้น ทำให้ภาพที่ได้ไม่สมบูรณ์',
        'เมื่อรวมกับทักษะการตัดต่อ จะช่วยแก้ไขผลลัพธ์ที่ผิดเพี้ยนของ AI สร้างภาพ ส่งผลให้ภาพที่ได้มีความสมบูรณ์มากที่สุด',
        'สามารถใช้งาน AI open source เช่น Llama ซึ่งเป็นโมเดล AI ที่แจกฟรี มาพัฒนาต่อยอดได้ ทำให้สามารถสร้างโปรแกรม AI ที่ตอบสนองต่อความต้องการขององค์กรอย่างจำเพาะได้',
    ],
    example: [
    ],
};