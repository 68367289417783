import React from 'react';

const Swiper_Navigation = ({boxicon_name, handle_onclick}) => {
    return (
        <div className='w-fit h-fit bg-white border border-gray-300 rounded-full p-2 drop-shadow cursor-pointer flex justify-center items-center' 
        onClick={handle_onclick}>
            <box-icon name={boxicon_name}></box-icon>
        </div>
    )
}

export default Swiper_Navigation