import React from 'react';
import "./home.css";
import Social from './Social';
import Data from './Data';
import ScrollDown from './ScrollDown';

import { delay, motion } from "framer-motion";

const Home_Mobile = () => {
  return (
    <>
      <div className='w-fit h-fit flex gap-5 md:gap-20 mx-auto md:mx-0'>
        <div className='scale-90'>
          <Social/>
        </div>
        
        <div className="home__img mb-10 sm:mb-0 scale-110 translate-y-4"></div>
      </div>

      <div className='w-fit h-fit mx-auto'>
        <Data/>
      </div>
    </>
  )
}

const Home_Default = () => {
  return (
    <>
      <div className='scale-90'>
        <Social/>
      </div>

      <div className="home__img mb-10 sm:mb-0 scale-110 translate-y-4"></div>

      <div className='w-fit h-fit mx-auto'>
        <Data/>
      </div>
    </>
  )
}

const Home = () => {
  return (
    <section className="home section" id="home">
      <div className="block md:hidden w-full h-fit">
        <Home_Mobile/>
      </div>

      <div className="hidden md:flex gap-10 xl:gap-14 w-fit h-fit mx-auto">
        <Home_Default/>
      </div>

      <ScrollDown/>
    </section>
  )
}

export default Home